import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiConfig, IGetListOption } from "../api.types";
import { ApiService } from "../api.service";
import {
  IShipment,
  IShipmentCreateRequest,
  IShipmentCreateResponse, IShipmentGoods,
  IShipmentUpdateRequest
} from "./shipment-rest-api.types";


@Injectable({
  providedIn: 'root',
})
export class ShipmentRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Количество записей отгрузок.
   * @param options - Опции лимитов, сортировки, фильтрации, пагинации.
   * @description   - Метод возвращает общее количество идентификаторов отправлений товаров клиентам.
   *                  Метод доступен аутентифицированному пользователю.
   */
  public count(options?: IGetListOption): Observable<number> {
    const urn: string = this.createUri(`/shipment/count`);
    return this.apiCount(urn, options, undefined);
  }

  /**
   * Список идентификаторов отгрузок.
   * @param options      - Опции лимитов, сортировки, фильтрации.
   * @param accessToken  - Токен доступа при аутентификации с доступом к токену.
   * @description        - Метод возвращает список идентификаторов отправлений товаров клиентам.
   *                       Метод доступен аутентифицированному пользователю.
   */
  public listId(options?: IGetListOption, accessToken?: string): Observable<number[]> {
    const urn: string = this.createUri(`/shipment`);
    return this.apiListId(urn, options, accessToken);
  }

  /**
   * Подробная информация об отгрузке.
   * @param ids         - Идентификаторы через запятую.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description       - Получение подробной информации об отгрузке посылки клиенту.
   *                      В качестве идентификатора можно передавать один или несколько идентификаторов,
   *                      разделённых запятой.
   *                      Метод доступен аутентифицированному пользователю.
   */
  public listInfo(ids: number[], accessToken?: string): Observable<IShipment[]> {
    const urn: string = this.createUri(`/shipment/${ids.join(',')}`);
    return this.apiListInfo<IShipment>(
      urn,
      ids,
      (item: IShipment) => {
        item.goods.forEach((_: IShipmentGoods, index:number): void => {
          const createAt: string | Date | undefined = item.goods[index].createAt;
          item.goods[index].createAt = createAt ? new Date(createAt) : undefined;
        });
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
          paidAt: item.paidAt ? new Date(item.paidAt) : undefined,
          isShippedAt: item.isShippedAt ? new Date(item.isShippedAt) : undefined,
          isReceivedAt: item.isReceivedAt ? new Date(item.isReceivedAt) : undefined,
        });
      },
      accessToken,
    );
  }

  /**
   * Создание новой отгрузки.
   * @param shipment - Данные формы редактирования.
   * @description    - Метод создаёт запись об отгрузке посылки клиенту.
   *                   Метод доступен аутентифицированному пользователю.
   */
  public create(shipment: IShipmentCreateRequest): Observable<IShipmentCreateResponse | null> {
    const urn: string = this.createUri(`/shipment`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<IShipmentCreateResponse>(urn, shipment, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<IShipmentCreateResponse>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Изменение свойств отгрузки.
   * @param shipmentId - Уникальный идентификатор отгрузки.
   * @param shipment   - Данные формы редактирования.
   * @description      - Метод изменяет часть свойств отгрузки.
   *                     Метод доступен аутентифицированному пользователю.
   */
  public update(shipmentId: number, shipment: IShipmentUpdateRequest): Observable<void | null> {
    const urn: string = this.createUri(`/shipment/${shipmentId}`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .patch<void>(urn, shipment, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Удаление отгрузки.
   * @param shipmentId  - Уникальный идентификатор отгрузки.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description       - Метод выполняет удаление отгрузки.
   *                      Метод доступен аутентифицированному пользователю.
   */
  public delete(shipmentId: number, accessToken?: string): Observable<void | null> {
    const urn: string = this.createUri(`/shipment/${shipmentId}`);
    return this.apiDelete<void>(urn, accessToken);
  }
}
