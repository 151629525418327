/** Конфигурация запросов к API. */
export class ApiConfig {
  endpoint!: string;
  backend!: string;
}

/** Данные заголовка с рекомендуемым временем ожидания до следующего запроса. */
export interface IResponseWithRetryAfter {
  waitTimeSec?: number;
}

/** Параметры запросов с сортировкой, фильтрацией и пагинацией. */
export interface IGetListOption {
  limit?: IGetListOptionLimit;
  by?: IGetListOptionOrder[];
  filter?: IGetListOptionFilter[];
  tie?: IGetListOptionTie;
}

/** Тип способа сравнения значения для фильтрации запросов. */
export type IGetListOptionType = 'eq' | 'lt' | 'le' | 'gt' | 'ge' | 'ne' | 'ke' | 'kn';

/**
 * Режим простой фильтрации.
 * Режим составления условий фильтрации. Может принимать два значения:
 * - and — Все условия объединяются в один запрос как логическое И (по умолчанию).
 * - or — Все условия объединяются в один запрос как логическое ИЛИ.
 */
export type IGetListOptionTie = 'and' | 'or';

/** Лимит результата. */
export interface IGetListOptionLimit {
  /** Количество элементов в ответе. */
  limit?: number;
  /** Порядковый номер первого элемента. */
  offset?: number;
}

/** Имя поля сортировки результата. */
export interface IGetListOptionOrder {
  /** Имя поля объекта. */
  name: string;
  /** Порядок сортировки. */
  type: 'asc' | 'desc' | 'none';
}

/** Фильтрация данных. */
export interface IGetListOptionFilter {
  /** Имя поля. */
  name: string;
  /** Способ сравнения. */
  type: IGetListOptionType;
  /** Значение фильтрации. */
  value: string;
}

/** Стандартный ответ сервера на запрос создания новой сущности. */
export interface IApiCreateResponse {
  /** Уникальный идентификатор созданной сущности. */
  id: number;
}

/**
 * Режим выбора и применения фильтрации.
 * 'input'       - Режим по умолчанию - фильтрация задаётся полем ввода свободного значения и применяется к данным
 *                 методами: 'ke', 'kn'
 * 'datepicker'  - Тип фильтрации по дате. Фильтрация задаётся через выбор даты и применяется к данным
 *                 методами: 'eq', 'lt', 'le', 'gt', 'ge', 'ne'
 */
export type FiltrationType = 'input' | 'datepicker' | 'select';

/** Тип фильтрации контента для сводной таблицы. */
export type ContentFiltration = {
  /** Название фильтра. */
  name: string;

  /** Наименование колонки фильтрации для передачи в запрос фильтрации. */
  field: string;

  /** Тип сравнения значения. */
  type: IGetListOptionType;

  /** Режим выбора и применения фильтрации.  */
  mode?: FiltrationType;

  /** Дополнительные свойства внутри компонента для формирования UI элемента. */
  customs?: string;

  /** Элемент является элементом "конец строки". */
  rowEnd?: boolean;
}
