import { Component, OnInit } from '@angular/core';
import { SemVer } from "semver";

import { APP_CONFIG } from "../../app.config";
import { CoreRestApiService, IVersion } from "../../_services";


@Component({
  selector: 'component-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private readonly beginYear: string;
  private frontend?: SemVer = undefined;
  private backend?: IVersion = undefined;
  public copyrightYear: string;
  public isShowVersion: boolean;

  /**
   * Конструктор.
   */
  constructor(
    private coreService: CoreRestApiService,
  ) {
    [this.beginYear, this.copyrightYear, this.isShowVersion] = ['2024', '', false];
  }

  /** Инициализатор. */
  ngOnInit(): void {
    const now: Date = new Date();

    if (this.beginYear !== now.getFullYear().toString())
      this.copyrightYear = this.beginYear + '-' + now.getFullYear().toString();
    else this.copyrightYear = this.beginYear;
    this.frontend = new SemVer(APP_CONFIG.appVersion);
    this.coreService.version().subscribe((v: IVersion) => this.backend = v);
  }

  /** Переключение отображения версий компонентов приложения. */
  public toggleShowVersion(): void {
    this.isShowVersion = !this.isShowVersion;
  }

  /** Коротка версия бек-энд. */
  public get backendVersionShort(): string {
    let semver: SemVer;
    let ret: string = '-';
    if (this.backend === undefined) return ret;
    if (this.backend.version === '') return ret;
    semver = new SemVer(this.backend.version);
    ret = semver.version;
    ret = ret.replace(new RegExp('\-.*$'), '');
    return ret;
  }

  /** Коротка версия сборки бек-энд. */
  public get backendVersionBuild(): string {
    let ret: string = '-';
    let build: string[] = [];
    let semver: SemVer;

    if (this.backend === undefined) return ret;
    if (this.backend.version === '') return ret;
    semver = new SemVer(this.backend.version);
    build.push(...semver.build);
    if (build.length <= 2) ret = `${build[1]}`;
    else ret = `${build[1]}.${build[2]} (${build[3]})`;

    return ret;
  }

  /** Полная версия бек-энд. */
  public get backendVersionFull(): string {
    if (this.backend === undefined) return '-';
    return `v.${this.backend.version}`;
  }

  /** Версия схемы базы данных. */
  public get dbVersion(): string {
    if (this.backend === undefined) return '-';
    return this.backend.versionDb;
  }

  /** Последняя миграция базы данных. */
  public get dbMigration(): string | Date {
    if (this.backend === undefined) return '-';
    if (this.backend.versionDbAt === undefined) return '-';
    return this.backend.versionDbAt;
  }

  /** Коротка версия фронт-энд. */
  public get frontendVersionShort(): string {
    if (this.frontend === undefined) return '-';
    return this.frontend.version;
  }

  /** Коротка версия сборки фронт-энд. */
  public get frontendVersionBuild(): string {
    let ret: string;
    let build: string[] = [];

    if (this.frontend === undefined) return '-';
    build.push(...this.frontend.build);
    ret = `${build[1]}.${build[2]} (${build[3]})`;

    return ret;
  }

  /** Полная версия фронт-энд. */
  public get frontendVersionFull(): string {
    if (this.frontend === undefined) return '-';
    return `v.${this.frontend.raw}`;
  }

  /** Наименование Компании. */
  public get companyName(): string {
    return APP_CONFIG.companyName;
  }
}
