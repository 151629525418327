<div
  class="absolute bg-white border border-bbGrayTableBorder -ml-[50px] -mt-[8px] px-[8px] py-[8px] z-3">
  <div class="overflow-hidden flex flex-row w-[100px]">
    <input
      type="number" min="{{min}}" max="{{max}}" required
      [(ngModel)]="num"
      class="min-w-[70px] h-[28px] mr-0 pr-[4px] appearance-none rounded-none outline-none
             border border-bbGrayTableBorder text-center leading-[10px]">
    <button
      (click)="onEnter()"
      class="w-[24px] appearance-none rounded-none outline-none border-transparent
             p-0 m-0"
    ><component-sprite-icon [name]="'enter-52px-active'"></component-sprite-icon>
    </button>
  </div>
</div>
