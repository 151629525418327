<div class="flex flex-row">
  <div
    *ngIf="label !== ''"
    [ngClass]="labelClass"
    class="min-h-[32px] text-[14px] my-auto mr-1.5">{{label}}</div>
  <div class="min-h-[32px] flex">
    <fieldset
      class="select-none leading-none">
      <label
        for="{{id}}"
        class="inline-flex relative items-center cursor-pointer">
        <input
          id="{{id}}"
          type="checkbox"
          role="switch"
          [checked]="switch"
          (change)="onChanged($event)"
          [disabled]="isDisabled"
          class="sr-only peer">
        <div
          [ngClass]="{
            'bg-bbGrayToggleBg': !switch,
            'bg-bbBlueRegular peer-checked:bg-bbBlueRegular': switch && !isDisabled,
            'bg-bbGrayLight peer-checked:bg-bbGrayToggleBg': switch && isDisabled,
            'peer-checked:after:border-bbWhite after:bg-bbWhite after:border-bbWhite': !isDisabled,
            'peer-checked:after:border-bbGraySpace after:bg-bbGraySpace after:border-bbGraySpace': isDisabled}"
          class="w-[46px] h-[24px] rounded-full
                 peer peer-focus:outline-none peer-checked:after:translate-x-full
                 after:content-[''] after:absolute after:top-[2px] after:left-[3px]
                 after:border after:rounded-full after:h-[20px] after:w-[20px] after:transition-all">
          <!-- Иконка внутри чекбокса, видна только во включенном состоянии. -->
          <component-sprite-icon
            [name]="'check-52px-hover'" [width]="14" [height]="14"
            class="relative left-[6px] top-[4px]"></component-sprite-icon>
          <!-- /Иконка внутри чекбокса, видна только во включенном состоянии. -->
        </div>
      </label>
      <div
        class="flex justify-center text-[9px]">
        <span *ngIf="switch">{{labelYes}}</span>
        <span *ngIf="!switch">{{labelNot}}</span>
      </div>
    </fieldset>
  </div>
</div>
