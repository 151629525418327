import { Component } from '@angular/core';


@Component({
  selector: 'component-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
}
