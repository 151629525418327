import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, first } from 'rxjs/operators';
import { Observable } from "rxjs";

import { AuthService } from './auth.service';
import { ISessionFullInfoResponse } from "../api";


/**
 * Вызывается при построении роутинга.
 * В зависимости от аргументов, проверяет административный доступ пользователя.
 *
 * @param isAdministrator - Аргумент вида проверки.
 *                          Если передана "Истина" - проверка является ли пользователь администратором.
 *                          Если передана "Ложь" - проверка является ли пользователь обычным пользователем.
 * @param redirectTo      - URN для перехода в случае не пройденной проверки.
 * @param authService     - Сервис аутентификации.
 * @param router          - Сервис роутинга.
 *
 * @return - Observable | Promise | объект.
 */
export const AdminGuard = (
  isAdministrator: boolean,
  redirectTo: string,
  authService: AuthService = inject(AuthService),
  router: Router = inject(Router),
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  authService.checkSessionFullInfoRetry().finally(():void => {
    // console.log('Выполнен запрос API на получение полной информации о сессии пользователя');
  });
  return authService
    .isAdministrator$
    .pipe(
      first(),
      map((isAdmin: boolean): boolean => {
        switch (isAdmin !== isAdministrator) {
          case true:
            router.navigateByUrl(redirectTo).then((_: boolean): void => {
              // console.log(`Условие AdminGuard(${isAdministrator}) не выполнено, переход на URN: "${redirectTo}"`);
            });
            return false;
          default:
            return true;
        }
      })
    );
}
