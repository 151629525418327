import { Component, Input } from '@angular/core';

/**
 * Компонент переключения иконок при наведении.
 * @param iconPassive - Обычная иконка.
 * @param iconActive  - Иконка при наведении мыши.
 * @param width       - Ширина иконки.
 * @param height      - Высота иконки.
 */
@Component({
  selector: 'component-sprite-with-hover',
  templateUrl: './sprite-with-hover.component.html',
  styleUrls: ['./sprite-with-hover.component.scss']
})
export class SpriteWithHoverComponent {
  private isHover$: boolean;

  @Input() public iconPassive: string;
  @Input() public iconActive: string;
  @Input() public width: number;
  @Input() public height: number;

  /**
   * Конструктор.
   */
  constructor() {
    this.iconPassive = '';
    this.iconActive = '';
    this.width = 0;
    this.height = 0;
    this.isHover$ = false;
  }

  /** Возвращается состояние "при наведении". */
  public get isHover(): boolean {
    return this.isHover$;
  }

  /** Функция вызывается, когда мышка наводится на компонент. */
  public async onMouseEnter(): Promise<void> {
    this.isHover$ = true;
  }

  /** Функция вызывается, когда мышка уходит за пределы компонента. */
  public async onMouseLeave(): Promise<void> {
    this.isHover$ = false;
  }
}
