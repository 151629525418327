import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";

import { IBreadCumbs } from "./bread-crumbs.types";


/**
 * Сервис хлебных крошек.
 * Единый механизм установки значений хлебных крошек из всех компонентов.
 */
@Injectable({
  providedIn: 'root'
})
export class BreadCrumbsService {
  private readonly data$: ReplaySubject<IBreadCumbs[]>;

  /** Конструктор. */
  constructor() {
    this.data$ = new ReplaySubject(1); // Создание канала передачи данным с буфером в один элемент.
  }

  /**
   * Возвращает канал выгрузки данных для "хлебных крошек".
   * На данный канал подписывается только компонент "хлебные крошки".
   */
  public get dataSubject(): ReplaySubject<IBreadCumbs[]> {
    return this.data$;
  }

  /** Установка новых значений хлебных крошек. */
  public set breadCumbs(value: IBreadCumbs[]) {
    this.data$.next(value);
  }

  /** Очистка хлебных крошек. Компонент интерфейса скроется. */
  public clean(): void {
    this.data$.next([]);
  }
}
