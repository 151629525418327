import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'component-button-filter',
  templateUrl: './input-button-filter.component.html',
  styleUrls: ['./input-button-filter.component.scss']
})
export class InputButtonFilterComponent implements OnInit {
  private isHover$: boolean = false; // Истина, когда мышка наведена на кнопку.

  @Input('label') public label: string = ''; // Текст на кнопке.
  @Input('isActive') public isActive: boolean = false; // Состояние активности кнопки.
  @Output('clickFn') public clickEvent$: EventEmitter<MouseEvent>; // Функция клика.

  /**
   * Конструктор.
   */
  constructor() {
    this.clickEvent$ = new EventEmitter<MouseEvent>();
  }

  /** Инициализатор. */
  ngOnInit(): void {
  }

  /** Вызывается при входе мышки на элемент. */
  public onMouseEnter(): void {
    this.isHover$ = true;
  }

  /** Вызывается при выходе мышки за элемент. */
  public onMouseLeave(): void {
    this.isHover$ = false;
  }

  /** Возвращает состояние наведения выши на элемент. */
  public get isHover(): boolean {
    return this.isHover$;
  }

  /**
   * Получение события клика по кнопке.
   * @param $event - Описание события мыши.
   */
  public onClick($event: MouseEvent): void {
    if (this.clickEvent$ && !this.isActive) {
      this.clickEvent$.next($event);
    }
  }
}
