<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()">
  <component-sprite-icon
    *ngIf="iconPassive !== '' && !isHover"
    [name]="iconPassive" [width]="width" [height]="height"
  ></component-sprite-icon>
  <component-sprite-icon
    *ngIf="iconActive !== '' && isHover"
    [name]="iconActive" [width]="width" [height]="height"
  ></component-sprite-icon>
</div>
