import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { ApiConfig, IGetListOption } from '../api.types';
import { ApiService } from '../api.service';
import { Directory } from "../../directory";
import { IGoodsShort } from "../goods";


@Injectable({
  providedIn: 'root',
})
export class DictionaryRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Список идентификаторов сущности, определяемой на основе дженерика.
   * @param item        - Сущность справочника.
   * @param options     - Опции лимитов, сортировки, фильтрации.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description Метод возвращает список идентификаторов.
   *              Метод доступен аутентифицированному пользователю состоящему в группе администратор.
   */
  public listId(item: Directory, options?: IGetListOption, accessToken?: string): Observable<number[]> {
    const urn: string = this.createUri(`/${item.type}`);
    return this.apiListId(urn, options, accessToken);
  }

  /**
   * Подробная информация о сущности.
   * @param item        - Сущность справочника.
   * @param ids         - Идентификаторы через запятую.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description Получение подробной информации о сущности по идентификатору сущности.
   *              В качестве идентификатора можно передавать один или несколько идентификаторов, разделённых запятой.
   *              Метод доступен аутентифицированному пользователю состоящему в группе администратор.
   */
  public listInfo(item: Directory, ids: number[], accessToken?: string): Observable<any> {
    const urn: string = this.createUri(`/${item.type}/${ids.join(',')}`);
    return this.apiListInfo<any>(
      urn,
      ids,
      (item) => {
        switch (item.type) {
          case 'client/with-goods':
            item.goods.forEach((_: IGoodsShort, index:number): void => {
              const createAt: string | Date | undefined = item.goods[index].createAt;
              item.goods[index].createAt = createAt ? new Date(createAt) : undefined;
            });
        }
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
          updateAt: item.updateAt ? new Date(item.updateAt) : undefined,
        });
      },
      accessToken,
    );
  }
}
