import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, Observable } from "rxjs";

import { ApiConfig } from '../api.types';
import { ApiService } from '../api.service';
import { ISettings } from "./settings-rest-api.types";


/**
 * Сервис работы с серверным хранилищем ключ/значение.
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Получение ключ/значение с сервера.
   * @param keys - Один или несколько ключей.
   * @description Метод возвращает сохранённые на сервере пары ключ/значение. Если ключи не были сохранены,
   *              но запрошены, то у них выставляются значения равные null.
   *              Сохраняемые и запрашиваемые ключи привязаны к пользователю и не зависят от сессии пользователя.
   *              Назначение — сохранение на сервере настроек интерфейса пользователя для возможности работать с
   *              одинаковыми настройками из разных браузеров или разных рабочих мест одному пользователю.
   *              Если запрос не содержит параметров, то сервер выгружает все сохранённые ранее ключи пользователя.
   *              Если все ключи не нужны, то в качестве параметров можно перечислить необходимые ключи.
   *              Тип значения может быть любой, включая объект. Значение на сервере не обрабатывается и сохраняется
   *              как массив байт.
   *              Метод доступен любому авторизованному пользователю вне зависимости от групп доступа.
   */
  public load(keys: string[]): Observable<ISettings> {
    const urn = this.createUri('/settings/kv');
    let params: HttpParams = new HttpParams();

    keys.forEach(key => params = params.append('key', key));
    return this.http.get<ISettings>(urn, {
      observe: 'response',
      params,
    })
      .pipe(
        map((response: HttpResponse<any>) => response.body),
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 404:
              return this.handleError(
                error,
                'Настройки отсутствуют.',
                false
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        })
      );
  }

  /**
   * Сохранение ключ/значение на сервере.
   * @param data - Объект содержащий ключи и значения.
   * @description Метод сохраняет на сервере пары ключ/значение. Сохраняемые и запрашиваемые ключи привязаны к
   *              пользователю и не зависят от сессии пользователя.
   *              Назначение — сохранение на сервере настроек интерфейса пользователя для возможности работать с
   *              одинаковыми настройками из разных браузеров или разных рабочих мест одному пользователю.
   *              Тип значения может быть любой, включая объект. Значение на сервере не обрабатывается и сохраняется
   *              как массив байт.
   *              Если значение ключа равно null, то ключ будет удалён — эквивалентно запросу удаления ключа.
   *              Важно лишь то чтобы общий JSON объект был корректным JSON объектом.
   *              Метод доступен любому авторизованному пользователю вне зависимости от групп доступа.
   */
  public save(data: ISettings) {
    const urn: string = this.createUri('/settings/kv');
    let params: HttpParams = new HttpParams();

    return this.http.put<void>(urn, data, {
      observe: 'response',
      params,
    })
      .pipe(
        map(_ => true),
        catchError(error => {
          switch (error.status) {
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        })
      );
  }

  /**
   * Удаление ключ/значение на сервере.
   * @param keys - Список удаляемых ключей.
   * @description Метод удаляет на сервере пары ключ/значение по имени ключа.
   *              Сохраняемые, запрашиваемые и удаляемые ключи привязаны к пользователю и не зависят от сессии
   *              пользователя.
   *              Метод доступен любому авторизованному пользователю вне зависимости от групп доступа.
   */
  public delete(keys: string[]) {
    const urn = this.createUri('/settings/kv');
    let params: HttpParams = new HttpParams();

    keys.forEach(key => {
      params = params.append('key', key);
    });
    return this.http.delete<void>(urn, {observe: 'response', params})
      .pipe(
        map(_ => true),
        catchError(error => {
          switch (error.status) {
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        })
      );
  }
}
