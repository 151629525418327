import { AfterViewChecked, Directive, ElementRef, HostListener, OnInit } from '@angular/core';


@Directive({
  selector: '[textareaContentHeight]',
  host: {
    '(focus)': 'onFocus()',
  }
})
export class TextareaContentHeightDirective implements OnInit, AfterViewChecked {
  /** Конструктор. */
  constructor(
    private elementRef: ElementRef,
  ) {
  }

  /** Инициализатор. */
  ngOnInit(): void {
    this.resize();
  }

  /**
   * Вызывается фреймворком Angular после проверки на изменения в представлении компонента,
   * а также проверки представлений дочерних компонентов.
   */
  ngAfterViewChecked(): void {
    this.resize();
  }

  /**
   * Обработка события отпускания клавиши клавиатуры на HTML элементе.
   */
  @HostListener('keyup', []) public onKeyup(): void {
    this.resize();
  }

  /**
   * Обработка события установки фокуса на HTML элемент.
   */
  onFocus(): void {
    this.resize();
  }

  /**
   * Выполнение "подгонки" высоты HTML элемента под размер контента.
   */
  private resize(): void {
    const nativeElement = this.elementRef.nativeElement;
    nativeElement.style.height = '1px';
    nativeElement.style.height = nativeElement.scrollHeight + 'px';
  }
}
