<div
  class="px-[10px] text-[12px]">

  <!-- Кнопка выдающая себя за select -->
  <button
    (click)="toggle()"
    class="w-[80px] relative px-[8px] sm:text-sm leading-relaxed dropdown:block rounded-none
             border border-bbGrayTableBorder bg-white transition-colors duration-150 hover:border-bbGrayTableSelected
             focus:outline-none focus:ring-bbBlueRegular focus:border-bbBlueRegular">

    <!-- Видимый всегда элемент с текущим выбором. -->
    <div class="flex items-center w-full">
      <span class="w-full px-[2px] text-left">{{selected}}</span>
      <component-sprite-icon
        *ngIf="!isShow"
        [name]="'dropdown-down-52px-active'" [width]="10" [height]="10"></component-sprite-icon>
      <component-sprite-icon
        *ngIf="isShow"
        [name]="'dropdown-up-52px-active'" [width]="10" [height]="10"></component-sprite-icon>
    </div>
    <!-- /Видимый всегда элемент с текущим выбором. -->

    <!-- Выпадающий список доступных значений. -->
    <ul
      [ngClass]="{'hidden': !isShow}"
      class="absolute -left-[1px] w-[80px] leading-[14px] bg-white border border-bbBlueRegular border-t-bbWhite">
      <a
        *ngFor="let item of items"
        (click)="$event.stopPropagation(); select(item);"
        [ngClass]="{
          'text-bbBlueRegular font-bold bg-bbGrayTableSelected': isSelected(item),
          'text-bbBlack': !isSelected(item)}"
        class="text-left inline-block w-full px-[10px] py-1  transition-colors duration-150
               focus:outline-none focus:shadow-outline hover:bg-bbBlueRegular hover:text-bbWhite"
      >{{item}}</a>
    </ul>
    <!-- /Выпадающий список доступных значений. -->

  </button>
  <!-- /Кнопка выдающая себя за select -->

</div>
