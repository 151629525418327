import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ApiConfig, IResponseWithRetryAfter } from '../api.types';
import { ApiService } from '../api.service';
import {
  ISessionCreateRequest, ISessionCreateResponse,
  ISessionFullInfoResponse,
  ISessionTimeInfoResponse,
} from './session-rest-api.types';

const zeroTime: string = '0001-01-01T00:00:00Z';


@Injectable({
  providedIn: 'root',
})
export class SessionRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Верификация сессии аутентификации.
   * @param accessToken - Сессионный токен доступа.
   * @description Метод выполняет проверку сессии аутентификации.
   *              ВНИМАНИЕ! Данный метод не продлевает время жизни сессии.
   */
  public checkSession(accessToken?: string): Observable<boolean> {
    let urn = this.createUri(`/session`);
    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .head<void>(urn, {observe: 'response'})
      .pipe(
        map((_: HttpResponse<void>) => true),
        catchError((error) => {
          switch (error.status) {
            case 401:
              return of(false);
            case 500:
              return this.handleError(error, 'COMMON__API_ERROR__INTERNAL');
            default:
              return this.handleError(error, 'COMMON__API_ERROR__UNKNOWN');
          }
        })
      );
  }

  /**
   * Аутентификация.
   * @param request - Параметры запроса.
   * @description Метод выполняет создание сессии аутентификации или создание токена авторизации для аутентификации
   *              с использованием второго фактора, в зависимости от настроек пользователя.
   */
  public createSession(request: ISessionCreateRequest): Observable<ISessionCreateResponse | IResponseWithRetryAfter | undefined> {
    const urn = this.createUri('/session');
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<ISessionCreateResponse>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<ISessionCreateResponse>) => {
          if (response.status === 204) {
            return undefined;
          }
          return Object.assign(
            {},
            response.body,
            this.getWaitTime(response)
          );
        }),
        catchError((error) => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Переданы не верные данные.',
                true
              );
            case 419:
              return this.handleError(
                error,
                'Переданы устаревшие данные.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        })
      );
  }

  /**
   * Получение значения заголовка Retry-After для ожидания перед выполнением следующего запроса.
   * @param response - Объект ответа на запрос к серверу.
   */
  protected getWaitTime(response: HttpResponse<any>): IResponseWithRetryAfter {
    return {
      waitTimeSec: parseInt(response.headers.get('Retry-After')!, 10),
    };
  }

  /**
   * Получение полной информации о сессии по токену.
   * @param accessToken - Сессионный токен доступа.
   * @description Метод возвращает описание доступа, которые были скопированы в сессию, в момент прохождения
   *              аутентификации.
   *              ВНИМАНИЕ! Данный метод не продлевает время жизни сессии.
   */
  public getSessionFullInfo(accessToken?: string): Observable<ISessionFullInfoResponse> {
    const headers: HttpHeaders = new HttpHeaders();
    let urn = this.createUri(`/session/info`);
    if (accessToken) {
      urn += `?accessToken=${accessToken}`;
    }
    return this.http
      .get<ISessionFullInfoResponse>(urn, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response:HttpResponse<ISessionFullInfoResponse>) => {
          return Object.assign({}, response.body, {
            createAt:
              response.body!.createAt &&
              response.body!.createAt !== zeroTime ? new Date(response.body!.createAt) : undefined,
            updateAt:
              response.body!.updateAt &&
              response.body!.updateAt !== zeroTime ? new Date(response.body!.updateAt) : undefined,
            expiresAt:
              response.body!.expiresAt &&
              response.body!.expiresAt !== zeroTime ? new Date(response.body!.expiresAt) : undefined,
          }) as ISessionFullInfoResponse;
        }),
        catchError((error) => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Сессия истекла или доступ не авторизованным пользователем.',
                false
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                false
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error);
          }
        })
      );
  }

  /**
   * Получение времени жизни сессии аутентификации.
   * @param accessToken Сессионный токен доступа
   * @description Метод возвращает краткую информацию о времени жизни текущей сессии аутентификации пользователя.
   *              ВНИМАНИЕ! Данный метод не продлевает время жизни сессии.
   */
  public getSession(accessToken?: string): Observable<ISessionTimeInfoResponse | undefined> {
    let urn = this.createUri(`/session`);
    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .get<ISessionTimeInfoResponse>(urn, {observe: 'response'})
      .pipe(
        map((response: HttpResponse<ISessionTimeInfoResponse>) => {
          return Object.assign({}, response.body, {
            createAt: response.body!.createAt ? new Date(response.body!.createAt) : undefined,
            expiresAt: response.body!.expiresAt ? new Date(response.body!.expiresAt) : undefined,
          }) as ISessionTimeInfoResponse;
        }),
        catchError((error) => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Сессия истекла или доступ не авторизованным пользователем.',
                false
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                false
              );
            case 500:
              return this.handleError(error, 'COMMON__API_ERROR__INTERNAL');
            default:
              return this.handleError(error, 'COMMON__API_ERROR__UNKNOWN');
          }
        })
      );
  }

  /**
   * Продление времени жизни сессии аутентификации.
   * @param accessToken - Сессионный токен доступа.
   * @description Метод выполняет проверку сессии аутентификации и продлевает время жизни сессии.
   *              Метод стоит использовать только для специальных случаев, так как продление сессии выполняется
   *              автоматически во всех методах подразумевающих активные действия пользователя, методы не
   *              продлевающие время жизни токена помечаются отдельно в заголовке.
   */
  public updateSession(accessToken?: string): Observable<ISessionTimeInfoResponse | undefined> {
    let urn = this.createUri(`/session`);
    const headers: HttpHeaders = new HttpHeaders();
    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .put<ISessionTimeInfoResponse>(urn, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: ISessionTimeInfoResponse) => {
          return Object.assign({}, {
            createAt: response.createAt ? new Date(response.createAt) : undefined,
            expiresAt: response.expiresAt ? new Date(response.expiresAt) : undefined,
          }) as ISessionTimeInfoResponse;
        }),
        catchError((error) => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Сессия истекла или доступ не авторизованным пользователем.',
                false
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                false
              );
            case 500:
              return this.handleError(error, 'COMMON__API_ERROR__INTERNAL');
            default:
              return this.handleError(error, 'COMMON__API_ERROR__UNKNOWN');
          }
        })
      );
  }

  /**
   * Удаление сессии аутентификации.
   * @param accessToken - Сессионный токен доступа.
   * @description Досрочное удаление сессии аутентификации.
   */
  public deleteSession(accessToken?: string): Observable<void | null> {
    let uri = this.createUri(`/session`);
    if (accessToken) {
      uri += `?accessToken=${accessToken}`;
    }
    return this.http
      .delete<void>(uri, {observe: 'response'})
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error) => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Сессия истекла или доступ не авторизованным пользователем.',
                false
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                false
              );
            case 500:
              return this.handleError(error, 'COMMON__API_ERROR__INTERNAL');
            default:
              return this.handleError(error, 'COMMON__API_ERROR__UNKNOWN');
          }
        })
      );
  }
}
