import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TextareaContentHeightDirective } from "./textarea-content-height.directive";


@NgModule({
  declarations: [
    TextareaContentHeightDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TextareaContentHeightDirective,
  ]
})
export class TextareaContentHeightModule { }
