import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiConfig, IGetListOption } from "../api.types";
import { ApiService } from "../api.service";
import {
  IClient,
  IClientCreateRequest,
  IClientCreateResponse,
  IClientUpdateRequest, IClientWithGoods,
} from "./client-rest-api.types";
import { IShipmentGoods } from "../shipment";
import { IGoodsShort } from "../goods";


@Injectable({
  providedIn: 'root',
})
export class ClientRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Количество клиентов.
   * @param options     - Опции лимитов, сортировки, фильтрации, пагинации.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description       - Метод возвращает общее количество клиентов.
   *                      Метод доступен аутентифицированному пользователю.
   */
  public count(options?: IGetListOption, accessToken?: string): Observable<number> {
    const urn: string = this.createUri(`/client/count`);
    return this.apiCount(urn, options, accessToken);
  }

  /**
   * Список идентификаторов клиентов.
   * @param options     - Опции лимитов, сортировки, фильтрации.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод возвращает список идентификаторов клиентов системы.
   *                Клиент — пользователь, который вышел на связь с ботом и выполнил команду /start.
   *                Метод доступен аутентифицированному пользователю.
   */
  public listId(options?: IGetListOption, accessToken?: string): Observable<number[]> {
    const urn: string = this.createUri(`/client`);
    return this.apiListId(urn, options, accessToken);
  }

  /**
   * Подробная информация о клиенте.
   * @param ids         - Идентификаторы через запятую.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Получение подробной информации о клиенте.
   *                В качестве идентификатора можно передавать один или несколько идентификаторов, разделённых запятой.
   *                Метод доступен аутентифицированному пользователю.
   */
  public listInfo(ids: number[], accessToken?: string): Observable<IClient[]> {
    const urn: string = this.createUri(`/client/${ids.join(',')}`);
    return this.apiListInfo<IClient>(
      urn,
      ids,
      (item: IClient) => {
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
          updateAt: item.updateAt ? new Date(item.updateAt) : undefined,
        });
      },
      accessToken,
    );
  }

  /**
   * Создание клиента.
   * @param client - Данные формы для создания пользователя.
   * @description - Метод создаёт запись о клиенте.
   *                Метод доступен аутентифицированному пользователю.
   */
  public create(client: IClientCreateRequest): Observable<IClientCreateResponse | null> {
    const urn: string = this.createUri(`/client`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<IClientCreateResponse>(urn, client, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<IClientCreateResponse>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 409:
              return this.handleError(
                error,
                'Конфликт. Уже существует клиент с тем же самым идентификатором пользователя телеграм.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Изменение свойств клиента.
   * @param clientId - Уникальный идентификатор пользователя.
   * @param client   - Данные формы редактирования пользователя.
   * @description    - Метод изменяет часть свойства записи о клиенте.
   *                   Метод доступен аутентифицированному пользователю.
   */
  public update(clientId: number, client: IClientUpdateRequest): Observable<void | null> {
    const urn: string = this.createUri(`/client/${clientId}`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .patch<void>(urn, client, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Удаление клиента.
   * @param clientId     - Уникальный идентификатор клиента.
   * @param accessToken  - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод выполняет удаление клиента и всю, связанную с клиентом информацию.
   *                Метод доступен аутентифицированному пользователю.
   */
  public delete(clientId: number, accessToken?: string): Observable<void | null> {
    const urn: string = this.createUri(`/client/${clientId}`);
    return this.apiDelete<void>(urn, accessToken);
  }

  /**
   * Список идентификаторов клиентов с товарами.
   * @param options     - Опции лимитов, сортировки, фильтрации.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод возвращает список идентификаторов клиентов системы, но только тех у которых:
   *                * добавлены товары;
   *                * хотя бы один товар пришёл на склад;
   *                * все пришедшие на склад товары ещё не отправлены;
   *                Метод предназначен для выборки списка клиентов при формировании отправления.
   *                Метод доступен аутентифицированному пользователю.
   */
  public withGoodsListId(options?: IGetListOption, accessToken?: string): Observable<number[]> {
    const urn: string = this.createUri(`/client/with-goods`);
    return this.apiListId(urn, options, accessToken);
  }

  /**
   * Подробная информация о клиенте дополненная информацией о товарах.
   * @param ids         - Идентификаторы через запятую.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description       - Получение подробной информации о клиенте, дополненной информацией о товарах принятых на склад,
   *                      но ещё не отправленных клиенту.
   *                      В качестве идентификатора можно передавать один или несколько идентификаторов, разделённых
   *                      запятой.
   *                      Метод доступен аутентифицированному пользователю.
   */
  public withGoodsListInfo(ids: number[], accessToken?: string): Observable<IClientWithGoods[]> {
    const urn: string = this.createUri(`/client/with-goods/${ids.join(',')}`);
    return this.apiListInfo<IClientWithGoods>(
      urn,
      ids,
      (item: IClientWithGoods) => {
        item.goods.forEach((_: IGoodsShort, index:number): void => {
          const createAt: string | Date | undefined = item.goods[index].createAt;
          item.goods[index].createAt = createAt ? new Date(createAt) : undefined;
        });
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
          updateAt: item.updateAt ? new Date(item.updateAt) : undefined,
        });
      },
      accessToken,
    );
  }
}
