import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiConfig, IGetListOption } from "../api.types";
import { ApiService } from "../api.service";
import { IGoods, IGoodsCreateRequest, IGoodsCreateResponse, IGoodsUpdateRequest } from "./goods-rest-api.types";


@Injectable({
  providedIn: 'root',
})
export class GoodsRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Количество записей идентификаторов товаров.
   * @param options - Опции лимитов, сортировки, фильтрации, пагинации.
   * @description   - Метод возвращает общее количество записей идентификаторов товаров клиентов.
   *                  Метод доступен аутентифицированному пользователю.
   */
  public count(options?: IGetListOption): Observable<number> {
    const urn: string = this.createUri(`/goods/count`);
    return this.apiCount(urn, options, undefined);
  }

  /**
   * Список идентификаторов товаров.
   * @param options      - Опции лимитов, сортировки, фильтрации.
   * @param accessToken  - Токен доступа при аутентификации с доступом к токену.
   * @description        - Метод возвращает список идентификаторов товаров клиентов.
   *                       Метод доступен аутентифицированному пользователю.
   */
  public listId(options?: IGetListOption, accessToken?: string): Observable<number[]> {
    const urn: string = this.createUri(`/goods`);
    return this.apiListId(urn, options, accessToken);
  }

  /**
   * Подробная информация о товаре.
   * @param ids         - Идентификаторы через запятую.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description       - Получение подробной информации о товаре.
   *                      В качестве идентификатора можно передавать один или несколько идентификаторов, разделённых
   *                      запятой.
   *                      Метод доступен аутентифицированному пользователю.
   */
  public listInfo(ids: number[], accessToken?: string): Observable<IGoods[]> {
    const urn: string = this.createUri(`/goods/${ids.join(',')}`);
    return this.apiListInfo<IGoods>(
      urn,
      ids,
      (item: IGoods) => {
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
        });
      },
      accessToken,
    );
  }

  /**
   * Добавление или изменение товара.
   * @param product - Данные формы редактирования.
   * @description   - Метод создаёт запись о товаре клиента.
   *                  Метод доступен аутентифицированному пользователю.
   */
  public create(product: IGoodsCreateRequest): Observable<IGoodsCreateResponse | null> {
    const urn: string = this.createUri(`/goods`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .put<IGoodsCreateResponse>(urn, product, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<IGoodsCreateResponse>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 409:
              return this.handleError(
                error,
                'Уже существует товар с указанным ID.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Изменение свойств товара.
   * @param productId - Уникальный идентификатор товара.
   * @param product   - Данные формы редактирования.
   * @description     - Метод изменяет часть свойств товара клиента.
   *                    Привязку к клиенту можно менять только если товар ещё не отправлен,
   *                    как только появляется shipmentId > 0, менять клиента нельзя.
   *                    Метод доступен аутентифицированному пользователю.
   */
  public update(productId: number, product: IGoodsUpdateRequest): Observable<void | null> {
    const urn: string = this.createUri(`/goods/${productId}`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .patch<void>(urn, product, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 409:
              return this.handleError(
                error,
                'Нельзя менять привязку товара к клиенту, товар уже прикреплен к отправлению.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Удаление товара.
   * @param productId    - Уникальный идентификатор товара.
   * @param accessToken  - Токен доступа при аутентификации с доступом к токену.
   * @description        - Метод выполняет удаление товара и/или пометку товара как удалённый.
   *                       Метод доступен аутентифицированному пользователю.
   */
  public delete(productId: number, accessToken?: string): Observable<void | null> {
    const urn: string = this.createUri(`/goods/${productId}`);
    return this.apiDelete<void>(urn, accessToken);
  }
}
