import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {
  transform(value: number): string {
    const days: number = Math.floor(value / (60 * 60 * 24));
    const hours: number = Math.floor((value - days * (60 * 60 * 24)) / (60 * 60));
    const minutes: number = Math.floor((value - hours * (60 * 60) - days * (60 * 60 * 24)) / 60);
    const seconds: number = value - (minutes * 60) - (hours * 60 * 60) - (days * (60 * 60 * 24));
    let ret: string = '';

    // Дней.
    if (days > 0) {
      ret += str_pad_left(days, '0', 1);
    }
    // Часов.
    if (ret !== '' || hours > 0) {
      if (ret !== '') ret += 'д. ';
      ret += str_pad_left(hours, '0', 2);
    }
    if (ret !== '') ret += ':';
    ret += str_pad_left(minutes, '0', 2);
    if (ret !== '') ret += ':';
    ret += str_pad_left(seconds, '0', 2);

    return ret;

    function str_pad_left(str: number, pad: string, length: number) {
      return (new Array(length + 1).join(pad) + str).slice(-length);
    }
  }
}
