import { ITableHead } from "../../_components";
import { FiltrationType, IGetListOptionType } from "../api";


/** Поддерживаемые типы справочников. */
export type DirectoryType = 'client' | 'client/with-goods';

/** Тип фильтрации справочника. */
export type DirectoryFiltration = {
  /** Название фильтра. */
  name: string;
  /** Наименование колонки фильтрации для передачи в запрос фильтрации. */
  field: string;
  /** Тип сравнения значения. */
  type: IGetListOptionType;
  /** Режим выбора и применения фильтрации.  */
  mode?: FiltrationType;
}

/** Тип карты заголовков таблицы. */
type DirectoryTableHeader = {
  [Type in DirectoryType]: {
    /** Настройки заголовка таблицы. */
    head: ITableHead[],
    /** Настройки фильтрации данных таблицы. */
    filtration?: DirectoryFiltration[],
  };
}

/** Справочники. */
export class Directory {
  /** Тип справочника. */
  type: DirectoryType;
  /** Название справочника. */
  name: string;
  /** Заголовки сводной таблицы. */
  tableHeader: ITableHead[];
  /**
   * Уникальный идентификатор сущности.
   * Используется при добавлении, редактировании сущности.
   * Значение =0 применяется для новой создаваемой сущности.
   * По умолчанию = -1 - Идентификатор не задан.
   */
  id: number;
  /** Объект сущности с типом данных возвращаемым по запросу info. */
  infoResponse?: any;
  /** Доступные фильтры данных справочника. */
  filtration?: DirectoryFiltration[];

  /**
   * Конструктор.
   * @param tpe - Тип сущности.
   * @param nme - Название сущности.
   */
  constructor(tpe: DirectoryType, nme: string) {
    [this.id, this.type, this.name] = [-1, tpe, nme];
    this.infoResponse = undefined;
    this.tableHeader = tableHeaderMap[tpe].head;
    this.filtration = tableHeaderMap[tpe].filtration;
  }
}

/** Карта со списком заголовков таблиц для всех типов справочников. */
const tableHeaderMap: DirectoryTableHeader = {
  /** Клиент. */
  'client': {
    head: [
      {name: 'Клиент', sort: 'none', by: 'name'},
    ],
    filtration: [
      {name: 'Идентификатор', field: 'uniqUserCode', type: 'ke'},
      {name: 'Имя', field: 'name', type: 'ke'},
      {name: 'Телеграм псевдоним клиента', field: 'telegramUsername', type: 'ke'},
    ],
  },

  /** Клиент с товарами на складе. */
  'client/with-goods': {
    head: [
      {name: 'Клиент', sort: 'none', by: 'name'},
    ],
    filtration: [
      {name: 'Идентификатор', field: 'uniqUserCode', type: 'ke'},
      {name: 'Имя', field: 'name', type: 'ke'},
      {name: 'Телеграм псевдоним клиента', field: 'telegramUsername', type: 'ke'},
    ],
  },
}
