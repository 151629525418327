import { environment } from '../environments/environment';
import { AuthServiceConfig, ApiConfig } from './_services';


/**
 * Структура конфигурации приложения.
 */
export interface IAppConfig {
  /* Режим запуска приложения angular. */
  production: boolean;

  /* Версия приложения angular. */
  appVersion: string;

  /** Наименование Компании. */
  companyName: string;

  /* Префикс API, описывает текущую используемую версию API. */
  api: ApiConfig;

  /* Конфигурация AuthService. */
  auth: AuthServiceConfig;

  // Локализация интерфейса.
  translate: {
    // Язык интерфейса по умолчанию.
    language?: string;
    localeValue: string;
    dateLocaleValue: string;
  };

  /** Название телеграм бота. */
  telegram_bot_name: string;
}

/**
 * Конфигурация приложения наполненная данным.
 */
export const APP_CONFIG: IAppConfig = {
  production: environment.production,
  appVersion: environment.version,
  companyName: 'China Link LTD',
  api: {
    endpoint: '/api/v1.0',
    backend: environment.backend,
  },
  auth: {
    defaultUnauthorizedUrn: '/auth/signin',
    unauthorizedUrnList: ['/auth/signin', '/auth/wait'],
    defaultAuthorizedUrn: '/index',
    authorizedUrnList: [
      '/index',
    ],
    authCheckTimeout: 15000,
  },
  translate: {
    language: 'rus',
    localeValue: 'ru',
    dateLocaleValue: 'ru-RU',
  },
  telegram_bot_name: environment.telegram_bot_name,
};
