import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subscriber, switchMap } from "rxjs";

import { Directory } from "./directory.types";
import { DictionaryRestApiService, IClient, IClientWithGoods, IGetListOption } from "../api";
import { IFieldsetDropboxDictionary } from "../../_ui";


/**
 * Сервис справочников.
 */
@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  /**
   * Конструктор.
   * @param dictionaryService - REST API сервис справочников.
   */
  constructor(
    private dictionaryService: DictionaryRestApiService,
  ) {
  }

  /** Справочники. */
  public directory(): Directory[] {
    return [
      new Directory('client', 'Клиент'),
    ];
  }

  /**
   * Загрузка данных справочника в массив для компонента отображения справочника в выпадающем списке.
   * @param item        - Объект сущности справочника для загрузки данных.
   * @param options     - Опции фильтрации, сортировки, пагинации загружаемых данных.
   * @param accessToken - Токен доступа, если есть.
   */
  public dropboxInfoById(
    item: Directory,
    options?: IGetListOption | undefined,
    accessToken?: string | undefined,
  ): Observable<IFieldsetDropboxDictionary[]> {
    return new Observable<IFieldsetDropboxDictionary[]>((data: Subscriber<IFieldsetDropboxDictionary[]>): void => {
      lastValueFrom(
        this.dictionaryService.listId(item, options, accessToken)
          .pipe(
            switchMap((ids: number[]) => this.dictionaryService.listInfo(item, ids, accessToken)),
          ))
        .then((rsp: any): void => {
          let ret: IFieldsetDropboxDictionary[] = [];

          switch (item.type) {
            // Клиент.
            case 'client':
              (rsp as IClient[]).forEach((elm: IClient): void => {
                let name: string = `${elm.uniqUserCode}, ${elm.name}`
                if (elm.telegramUsername !== '') name += `, @${elm.telegramUsername}`;
                ret.push({id: elm.id, name: name});
              });
              break

            // Клиент.
            case 'client/with-goods':
              (rsp as IClientWithGoods[]).forEach((elm: IClientWithGoods): void => {
                let name: string = `${elm.goods.length} шт., ${elm.uniqUserCode}, ${elm.name}`
                if (elm.telegramUsername !== '') name += `, @${elm.telegramUsername}`;
                ret.push({id: elm.id, name: name});
              });
              break
          }
          // Возвращает готовые данные.
          data.next(ret);
        })
        .catch((): void => {
          data.next([]);
        })
        .finally(() => data.complete());
    });
  }
}
