import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject, Subscription } from "rxjs";


/**
 * Компонент настраиваемой кнопки в стиле проекта.
 * @param label       - Текст отображаемый на кнопке.
 * @param labelLeft   - Флаг размещения текста на кнопке по центру.
 * @param iActive     - Название иконки активной кнопки, если пусто, иконка не отображается.
 * @param iPassive    - Название иконки отключённой кнопки, если пусто, иконка не отображается.
 * @param iHover      - Название иконки при наведении мышки на кнопку, если пусто, иконка не отображается.
 * @param iBusy       - Название иконки отображаемой при отключенной кнопке, если пусто, иконка не отображается.
 * @param button-type - Тип кнопки, например "submit". Если не указано, атрибут указания типа не добавляется вовсе.
 * @param busy        - Канал переключения состояния кнопки "активна" и "отключена".
 * @return onClick    - Канал передачи события клика по кнопке.
 */
@Component({
  selector: 'component-input-button-regular',
  templateUrl: './input-button-regular.component.html',
  styleUrls: ['./input-button-regular.component.scss']
})
export class InputButtonRegularComponent implements OnInit, OnDestroy {
  private busySubscribe?: Subscription; // Подписка на канал получения состояния активности кнопки.
  private isBusy$: boolean = false; // Истина, когда кнопка отключена.
  private isHover$: boolean = false; // Истина, когда мышка наведена на кнопку.

  public buttonStyleNormal: string; // Стиль кнопки без наведения.
  public buttonStyleHover: string; // Стиль кнопки при наведении.

  @Input('label') public label: string = '';
  @Input('labelLeft') public labelLeft: boolean = false;
  @Input('iActive') public iconActive: string = '';
  @Input('iPassive') public iconPassive: string = '';
  @Input('iHover') public iconHover: string = '';
  @Input('iBusy') public iconBusy: string = '';
  @Input('button-type') public type$?: string;
  @Input('busy') public busy$!: ReplaySubject<boolean>;
  @Input('activeStyleNormal') public activeStyleNormal: string = '';
  @Input('activeStyleHover') public activeStyleHover: string = '';
  @Output('onClick') readonly click$: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  /**
   * Конструктор.
   */
  constructor() {
    this.buttonStyleNormal = '';
    this.buttonStyleHover = '';
  }

  /** Инициализатор. */
  ngOnInit(): void {
    try {
      this.busySubscribe = this.busy$.subscribe((value: boolean): void => {
        this.isBusy$ = value;
        this.makeButtonStyle(); // Формирование стиля кнопки.
      });
    } catch (e: unknown) {
      // console.error('В компонент ButtonRegularComponent не передан канал изменения состояния активности кнопки.');
    }
    this.makeButtonStyle(); // Формирование стиля кнопки.
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.busySubscribe) {
      this.busySubscribe.unsubscribe();
      this.busySubscribe = undefined;
    }
  }

  /** Формирование стиля кнопки. */
  private makeButtonStyle(): void {
    this.buttonStyleHover = '';
    this.buttonStyleNormal = 'cursor-pointer';
    if (this.activeStyleNormal === '') this.buttonStyleNormal += 'border-bbBlueButtonBorder';
    else this.buttonStyleNormal += ' ' + this.activeStyleNormal;
    if (this.isBusy$) this.buttonStyleNormal = 'cursor-not-allowed border-bbGrayTableBorder bg-bbGrayTableSelected';
  }

  /** Возвращает состояние наведения выши на элемент. */
  public get isHover(): boolean {
    return this.isHover$;
  }

  /** Возвращает состояние "кнопка отключена". */
  public get isDisabled(): boolean {
    return this.isBusy$
  }

  /** Возвращает состояние наличия иконки. */
  public get isIcon(): boolean {
    return this.iconActive !== '' || this.iconPassive !== '' || this.iconHover !== '' || this.iconBusy !== '';
  }

  /** Возвращает название иконки в компоненте спрайта. */
  public get iconName(): string {
    let ret = this.iconActive;
    if (this.isBusy$) ret = this.iconBusy;
    if (!this.isBusy$) {
      if (!this.isHover$ && this.iconPassive !== '') ret = this.iconPassive;
      if (this.isHover$ && this.iconHover !== '') ret = this.iconHover;
    }
    return ret;
  }

  /** Вызывается при входе мышки на элемент. */
  public onMouseEnter(): void {
    if (this.activeStyleHover === '')
      !this.isDisabled ? this.buttonStyleHover = 'border-bbVioletRegular bg-bbVioletRegular text-bbWhite' : '';
    else
      !this.isDisabled ? this.buttonStyleHover = 'border-bbVioletRegular ' + this.activeStyleHover : '';
    this.isHover$ = true;
  }

  /** Вызывается при выходе мышки за элемент. */
  public onMouseLeave(): void {
    this.buttonStyleHover = '';
    this.isHover$ = false;
    this.makeButtonStyle(); // Формирование стиля кнопки.
  }

  /** Получение события клика по кнопке. */
  public onClick($event: MouseEvent): void {
    if (!this.isBusy$) {
      this.click$.next($event);
    }
  }
}
