import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { AuthService, ISessionFullInfoResponse } from "../../_services";


@Component({
  selector: 'button-signout',
  templateUrl: './button-signout.component.html',
  styleUrls: ['./button-signout.component.scss']
})
export class ButtonSignoutComponent implements OnInit, OnDestroy {
  private authSubscription?: Subscription = undefined;
  private sessionSubscription?: Subscription = undefined;
  private authInfo?: ISessionFullInfoResponse = undefined;
  private timer: ReturnType<typeof setTimeout> | undefined;
  private remaining: number = 0;

  public isAuthenticated: boolean = false;

  /**
   * Конструктор.
   * @param auth - Сервис аутентификации.
   */
  constructor(
    private auth: AuthService,
  ) {
  }

  /**
   * Инициализатор.
   */
  ngOnInit(): void {
    this.authSubscription = this.auth.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
      this.isAuthenticated = isAuthenticated;
    })
    this.sessionSubscription = this.auth.authSessionFullInfo$.subscribe((sfi: ISessionFullInfoResponse) => {
      this.authInfo = sfi;
      if (!this.timer) this.updateTimer();
      if (this.timer) {
        this.remaining = this.authInfo.lifetime;
      }
    });
    this.updateTimer();
    this.auth.checkSessionFullInfoRetry().then((_: void) => {
    });
  }

  /**
   * Деструктор.
   */
  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
      this.remaining = 0;
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
      this.authSubscription = undefined;
    }
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = undefined;
    }
  }

  /** Обновление статуса таймера. */
  private updateTimer(): void {
    // Если сессия истекла или не пришла.
    if (this.authInfo === undefined) {
      this.remaining = 0;
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = undefined;
        this.authInfo = undefined;
      }
      return;
    }
    // Если сессия аутентификации  действующая, запускам отсчёт.
    this.remaining = this.authInfo.lifetime;
    this.timer = setInterval(() => {
      let retry: boolean = false;

      this.remaining--;
      if (void 0 === this.remaining || this.remaining <= 0) {
        clearInterval(this.timer);
        this.timer = undefined;
        this.remaining = 0;
        this.authInfo = undefined;
        this.auth.checkSessionFullInfoRetry().then((_: void) => {
        });
        return
      }
      // Запрашиваем новую информацию о времени сессии:
      // - каждые 30 секунд если время жизни сессии больше минуты.
      // - каждые 10 секунд, если время жизни сессии меньше минуты.
      if (this.remaining > 60 && this.remaining % 30 === 0) retry = true;
      if (this.remaining < 60 && this.remaining % 10 === 0) retry = true;
      if (retry) {
        this.auth.checkSessionFullInfoRetry().then((_: void) => {
        });
      }
    }, 1000);
  }

  /** Получение значения оставшегося времени в секундах. */
  public get remainingTime(): number {
    return this.remaining
  }

  /** Обработчик кнопки завершения сессии авторизации. */
  public async onSignOut() {
    this.auth.cleanSession();
    clearInterval(this.timer);
    this.timer = undefined;
    this.remaining = 0;
    this.updateTimer();
  }
}
