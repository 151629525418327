<div
  class="flex">
  <div
    *ngFor="let section of sections"
    class="flex -mb-[4px] text-[16px]">
    <a
      *ngIf="isShow(section)"
      [routerLink]="section.link"
      routerLinkActive="border-b-[2px] border-b-bbWhite bg-bbBlueRegular text-bbWhite"
      [ngClass]="{
        'border-bbBlueRegular': isActive(section.link),
        'border-bbWhite': !isActive(section.link)}"
      class="border rounded-[4px]">
      <div
        [ngClass]="{'border-b-[2px] border-b-bbWhite': isActive(section.link)}"
        class="my-auto px-[14px] py-[5px] -ml-[1px] -mr-[1px] capitalize select-none">{{section.name}}</div>
    </a>
  </div>
</div>
