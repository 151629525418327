<div
  *ngIf="data.length > 0"
  class="w-full min-h-[28px] mt-[24px] bg-bbWhite border-b border-b-bbGraySpace text-[14px] flex flex-row">
  <div
    *ngFor="let item of data; index as n;"
    class="flex flex-row">
    <div *ngIf="n > 0" class="select-none px-[10px]">&gt;</div>
    <div
      [ngClass]="{'font-bold': data.length-1 === n}"
      class="text-bbBlueRegular uppercase select-none">
      <a
        *ngIf="isLink(n)"
        [routerLink]="item.urnInternal"
        class="select-none">{{item.name}}</a>
      <div *ngIf="!isLink(n)">{{item.name}}</div>
    </div>
  </div>
</div>
