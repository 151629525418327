/** Тип информации. */
export type infoType = 'start' | 'rules' | 'profile' | 'onWarehouse' | 'onShipped' | 'onReceived';

/** MIME тип простого текста. */
export type mimeTextPlain = 'text/plain';

/** MIME тип HTML. */
export type mimeTextHtml = 'text/html';

/** MIME тип маркдаун. */
export type mimeTextMarkdown = 'text/markdown';

/** MIME тип маркдаун версии 2. */
export type mimeTextMarkdownV2 = 'text/markdown-v2';

/** Сводный тип данных. */
export type infoContentType = mimeTextPlain | mimeTextHtml | mimeTextMarkdown | mimeTextMarkdownV2;

/** Структура данных информации. */
export interface IInfo {
  /** Формат передаваемого сообщения. */
  contentType: infoContentType;
  /** Контент. */
  content: string;
}

/** Мини справочник типов. */
export const infoMimeTypes: Map<infoContentType, string> = new Map([
  ['text/plain', 'Текст без разметки'],
  ['text/html', 'Текст с разметкой HTML'],
  ['text/markdown', 'Текст с разметкой Markdown'],
  ['text/markdown-v2', 'Текст с разметкой MarkdownV2'],
]);
