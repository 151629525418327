import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, first } from 'rxjs/operators';
import { Observable } from "rxjs";

import { AuthServiceConfig } from './auth.types';
import { AuthService } from './auth.service';


/**
 * Вызывается при построении роутинга.
 * В зависимости от аргументов, проверяет аутентифицирован ли пользователь или нет.
 *
 * @param isAuthenticated - Аргумент вида проверки.
 *                          Если передана "Истина" - проверка на аутентификацию пользователя.
 *                          Если передана "Ложь" - проверка на то что пользователь не аутентифицирован.
 * @param authService     - Сервис аутентификации.
 * @param router          - Сервис роутинга.
 * @param config          - Конфигурация сервиса аутентификации.
 *
 * @return - Observable | Promise | объект.
 */
export const AuthGuard = (
  isAuthenticated: boolean,
  authService: AuthService = inject(AuthService),
  router: Router = inject(Router),
  config: AuthServiceConfig = inject(AuthServiceConfig),
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return authService
    .isAuthenticated$
    .pipe(
      first(),
      map((isAuth: boolean): boolean => {
        let ret: boolean = false;

        switch (isAuthenticated) {
          case true:
            if (!isAuth) {
              router.navigateByUrl(config.defaultUnauthorizedUrn).then((_: boolean): void => {
              });
            }
            ret = true;
            break;
          default:
            if (isAuth) {
              router.navigateByUrl(config.defaultAuthorizedUrn).then((_: boolean): void => {
              });
            }
            ret = true;
            break;
        }

        return ret;
      })
    );
}
