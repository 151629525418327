import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { ReplaySubject, Subscription } from "rxjs";


@Component({
  selector: 'fieldset-switch',
  templateUrl: './fieldset-switch.component.html',
  styleUrls: ['./fieldset-switch.component.scss'],
})
export class FieldsetSwitchComponent implements OnInit, OnDestroy {
  private busySubscribe?: Subscription; // Подписка на канал получения состояния активности.
  public isBusy$: boolean = false; // Состояние активности переключателя.

  public readonly id: string = UUID.UUID();

  @Input('label') public label: string = '';
  @Input('labelClass') public labelClass: string = '';
  @Input('labelYes') public labelYes: string = 'Да';
  @Input('labelNot') public labelNot: string = 'Нет';
  @Input() public switch: boolean = false;
  @Input('busy') public busy$!: ReplaySubject<boolean>;
  @Output() public switchChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Конструктор.
   */
  constructor() {
  }

  /** Инициализатор. */
  ngOnInit(): void {
    try {
      this.busySubscribe = this.busy$.subscribe((value: boolean) => this.isBusy$ = value);
    } catch (e: unknown) {
      // console.error('В компонент FieldsetSwitchComponent не передан канал изменения состояния активности.');
    }
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.busySubscribe) {
      this.busySubscribe.unsubscribe();
      this.busySubscribe = undefined;
    }
  }

  /** Возвращает состояние активности переключателя. */
  public get isDisabled(): boolean {
    return this.isBusy$
  }

  /** Обработка события изменения состояния. */
  public onChanged($event: Event): void {
    this.switch = !this.switch;
    this.switchChange.next(this.switch);
  }
}
