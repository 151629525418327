import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener } from '@angular/core';
import { Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from "rxjs";

/**
 * Компонент отображения низа таблицы с возможностью изменения количества элементов таблицы на страницу.
 * @param items - Массив положительных чисел для выбора.
 * @param select - Выбранное по умолчанию число.
 * @return Канал возврата выбранного значения.
 */
@Component({
  selector: 'component-input-button-select',
  templateUrl: './input-button-select.component.html',
  styleUrls: ['./input-button-select.component.scss']
})
export class InputButtonSelectComponent implements OnInit, OnDestroy {
  private selectSubscribe?: Subscription; // Подписка на канал выбранного по умолчанию числа.
  private refreshSubscribe?: Subscription; // Подписка на канал обновления компонента.
  private isOpen: boolean = false; // Состояние видимости выпадающего списка.

  @Input('items') public items$: number[] = []; // Массив положительных чисел для выбора.
  @Input('select') public select$: number = -1; // Первоначальное значение выбранного по умолчанию числа.
  @Input('selectSubject') public selectSubject$!: Subject<number>; // Канал выбранного по умолчанию числа.
  @Input('refreshSubject') public refreshSubject$!: Subject<boolean>; // Канал события обновления компонента.
  @Output('itemSelected') public readonly itemSelected$; // Канал возврата выбранного значения.

  /**
   * Конструктор.
   * @param elementRef         - Объект со ссылкой на элементы DOM дерева.
   * @param changeDetectorRef  - Базовый класс, который обеспечивает функциональность обнаружения изменений.
   */
  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.itemSelected$ = new EventEmitter<number>();
  }

  /**
   * Инициализатор.
   */
  ngOnInit(): void {
    if (this.selectSubject$ === undefined) this.selectSubject$ = new Subject<number>();
    try {
      this.selectSubscribe = this.selectSubject$.subscribe((v: number) => {
        this.select$ = v;
      });
    } catch (e: unknown) {
      // console.error('В компонент TableFooterComponent не передан канал выбранного по умолчанию числа.');
    }
    try {
      this.refreshSubscribe = this.refreshSubject$.subscribe((value: boolean) => {
        this.changeDetectorRef.detectChanges();
      });
    } catch (e: unknown) {
      // console.error('В компонент TableFooterComponent не передан канал события обновления компонента.');
    }
    if (this.select$ <= 0) {
      if (this.items.length > 0) this.select$ = this.items[0];
    }
  }

  /**
   * Деструктор.
   */
  ngOnDestroy(): void {
    if (this.selectSubscribe) {
      this.selectSubscribe.unsubscribe();
      this.selectSubscribe = undefined;
    }
    if (this.refreshSubscribe) {
      this.refreshSubscribe.unsubscribe();
      this.refreshSubscribe = undefined;
    }
    this.items$ = [];
    this.select$ = -1;
    this.isOpen = false;
  }

  /** Функция присвоения для возможности обновления компонента без возврата события об изменении выбранного значения. */
  private set Select(n: number) {
    this.selectSubject$.next(n);
    this.isOpen = false;
  }

  /**
   * Подписка на все событие нажатия мышки.
   * @param event - Объект описания события.
   */
  @HostListener('document:mousedown', ['$event'])
  public onClick(event: MouseEvent): void {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  /** Возврат всех доступных элементов для выбора. */
  public get items(): number[] {
    return this.items$;
  }

  /** Возврат состояния видимости выпадающего списка. */
  public get isShow(): boolean {
    return this.isOpen;
  }

  /** Возврат текущего выбора. */
  public get selected(): number {
    return this.select$;
  }

  /** Проверка номера, выбран он или не выбран. */
  public isSelected(n: number): boolean {
    let ret = false;
    if (n == this.selected) ret = true;
    return ret;
  }

  /** Изменение выбранного номера. */
  public select(n: number): void {
    this.Select = n;
    this.itemSelected$.next(n);
  }

  /** Переключатель видимости выпадающего списка. */
  public toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
