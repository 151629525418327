<!-- Вставка iframe виджета телеграм. -->
<div
  #telegramauth
  *ngIf="isShowTelegramWidget"
  class="outline-none">
</div>
<!-- /Вставка iframe виджета телеграм. -->

<!-- Отображение ошибки авторизации через телеграм виджет. -->
<div
  *ngIf="error"
  class="w-[350px] min-h-[100px] text-justify text-2xl select-none rounded-[4px]
   bg-bbRedLight text-bbWhite font-roboto font-[24px] p-4">{{error}}
</div>
<!-- /Отображение ошибки авторизации через телеграм виджет. -->
