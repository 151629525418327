<div
  (click)="onClick($event)"
  [ngClass]="{
      'cursor-pointer border-bbBlueButtonBorder': !isActive,
      'cursor-not-allowed border-bbGrayTableBorder bg-bbGrayTableSelected': isActive}"
  class="border rounded-[4px] text-[14px]">
  <!-- Кнопка вписанная в контейнер, по горизонтали есть отступы. -->
  <button
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    [ngClass]="{
    'cursor-default': isActive,
    'text-bbBlack': isActive && !isHover,
    'cursor-not-allowed': isActive && isHover,
    'border-bbVioletRegular bg-bbVioletRegular text-bbWhite': !isActive && isHover}"
    class="w-full px-[12px] flex flex-row justify-between focus:outline-none">
    <!-- Текст на кнопки с позиционирование по горизонтали. -->
    <div
      [ngClass]="{'bg-rnYellow text-black': isActive}"
      class="w-full my-auto leading-[18px] text-left">{{label}}
    </div>
    <!-- /Текст на кнопки с позиционирование по горизонтали. -->

    <!-- Иконка. -->
    <component-sprite-icon
      *ngIf="isActive"
      [name]="'filter-52px-active'" [width]="16" [height]="16"
      [ngClass]="{'pl-[12px]': label !== ''}"
      class="py-[7px]"></component-sprite-icon>
    <component-sprite-icon
      *ngIf="!isActive && isHover"
      [name]="'filter-52px-hover'" [width]="16" [height]="16"
      [ngClass]="{'pl-[12px]': label !== ''}"
      class="py-[7px]"></component-sprite-icon>
    <component-sprite-icon
      *ngIf="!isActive && !isHover"
      [name]="'filter-52px-passive'" [width]="16" [height]="16"
      [ngClass]="{'pl-[12px]': label !== ''}"
      class="py-[7px]"></component-sprite-icon>
    <!-- /Иконка -->
  </button>
  <!-- /Кнопка вписанная в контейнер, по горизонтали есть отступы. -->
</div>
