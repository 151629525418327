import { ComponentRef, EmbeddedViewRef, Injectable, ViewContainerRef } from '@angular/core';


/**
 * Сервис создающий модальные окна с переданным в сервис компонентом.
 * @param viewContainerRef - Установщик значения.
 *                           До обращения к сервису необходимо передать объект типа ViewContainerRef.
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService<T1> {
  private componentRef: ComponentRef<T1> | undefined;
  private viewContainerRef$: ViewContainerRef | undefined;

  /**
   * Открытие модального окна.
   * @param viewContainerRef - Ссылка на объект View, в котором будет открываться модальное окно.
   * @param componentRef     - Объект компонента отображаемого в модальном окне.
   */
  public async open(
    viewContainerRef: ViewContainerRef,
    componentRef: ComponentRef<T1>,
  ): Promise<void> {
    this.viewContainerRef$ = viewContainerRef;
    this.componentRef = componentRef;
    if (this.viewContainerRef$) {
      const domElem: HTMLElement = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    }
  }

  /** Закрытие модального окна. */
  public async close(): Promise<void> {
    if (!this.componentRef) {
      return;
    }
    this.componentRef.destroy();
    this.componentRef = undefined;
  }
}
