import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { BreadCrumbsService, IBreadCumbs } from "../../_services";


@Component({
  selector: 'component-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit, OnDestroy {
  private dataSubscribe?: Subscription; // Подписка на изменение данных.
  private data$: IBreadCumbs[] = []; // Копия последних данных из канала данных.

  /**
   * Конструктор.
   * @param breadCrumbsService - Сервис обмена данными для отображения в хлебных крошках.
   */
  constructor(
    private breadCrumbsService: BreadCrumbsService,
  ) {
  }

  /** Инициализатор. */
  ngOnInit(): void {
    this.dataSubscribe = this.breadCrumbsService.dataSubject.subscribe((data: IBreadCumbs[])=>{
      this.data$ = data;
    });
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.dataSubscribe) {
      this.dataSubscribe.unsubscribe();
      this.dataSubscribe = undefined;
    }
  }

  /** Возвращает текущие данные для отображения в хлебных крошках. */
  public get data(): IBreadCumbs[] {
    return this.data$;
  }

  /** Возвращает разрешения установки ссылки. */
  public isLink(n: number): boolean {
    let ret: boolean = false;
    if (this.data$.length > n) {
      if (this.data$[n].urnInternal && n < this.data$.length-1) {
        ret = true;
      }
    }
    return ret;
  }
}
