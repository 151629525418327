import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, debounceTime, map } from "rxjs/operators";

import { ApiConfig } from "../api.types";
import { ApiService } from "../api.service";
import { IInfo, infoType } from "./info-rest-api.types";


@Injectable({
  providedIn: 'root',
})
export class InfoRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Получение информации.
   * @param infoType    - Тип информации.
   * @param accessToken - Сессионный токен доступа.
   * @description       - Метод загружает текущую информацию, которая выдаётся пользователям при общении с ботом.
   */
  public load(infoType: infoType, accessToken?: string): Observable<IInfo> {
    const headers: HttpHeaders = new HttpHeaders();
    const iType: string = infoType.toLowerCase();
    let urn: string = this.createUri(`/info/${iType}`);
    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .get<IInfo>(urn, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        }),
        map((response: HttpResponse<IInfo>) => (response as any).body),
        debounceTime(200),
      );
  }

  /**
   * Обновление информации.
   * @param infoType    - Тип информации.
   * @param info        - Объект содержащий разметку и контент обновляемой информации.
   * @param accessToken - Сессионный токен доступа.
   * @description Метод выполняет обновление информации.
   */
  public save(infoType: infoType, info: IInfo, accessToken?: string): Observable<void | null> {
    const headers: HttpHeaders = new HttpHeaders();
    const iType: string = infoType.toLowerCase();
    let urn: string = this.createUri(`/info/${iType}`);
    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .put<void>(urn, info, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Очистка информации.
   * @param infoType    - Тип информации.
   * @param accessToken - Сессионный токен доступа.
   * @description Метод выполняет очистку информации, в результате пользователям ничего выдаваться не будет.
   */
  public delete(infoType: infoType, accessToken?: string): Observable<void | null> {
    const iType: string = infoType.toLowerCase();
    let urn: string = this.createUri(`/info/${iType}`);
    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .delete<void>(urn, {observe: 'response'})
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Сессия истекла или доступ не авторизованным пользователем.',
                false
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                false
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        })
      );
  }
}
