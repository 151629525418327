<!-- Кнопка выхода. -->
<div *ngIf="isAuthenticated">
  <!-- Кнопка. -->
  <button
    (click)="onSignOut()"
    class="min-w-[35px] flex flex-row bg-bbWhite text-bbBlueRegular ml-auto
      border border-bbGrayRegular rounded-[4px]
      focus:outline-none
      hover:bg-bbVioletRegular hover:border-bbVioletRegular hover:text-bbWhite">
    <div class="m-[7px] ml-auto">
      <component-sprite-icon [name]="'signout-main-52px-active'" [width]="16" [height]="16"></component-sprite-icon>
    </div>
  </button>
  <!-- /Кнопка. -->
</div>
<!-- /Кнопка выхода. -->

<!-- Таймер завершения сессии аутентификации.-->
<div *ngIf="isAuthenticated && remainingTime > 0">
  <div class="font-golosUiRegular mt-[3px] text-[10px] text-bbGrayDark text-right">Выход через:
    {{remainingTime | hours}}</div>
</div>
<!-- /Таймер завершения сессии аутентификации.-->
