import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { Store } from '@ngrx/store';

import { APP_CONFIG } from "../../app.config";
import { TelegramUser } from "../../_types";
import {
  AuthService,
  IResponseWithRetryAfter,
  ISessionCreateRequest,
  ISessionCreateResponse,
  SessionRestApiService
} from "../../_services";
import { Update } from "../../_reducers";


@Component({
  selector: 'component-telegram-auth',
  templateUrl: './telegram-auth.component.html',
  styleUrls: ['./telegram-auth.component.scss']
})
export class TelegramAuthComponent implements AfterViewInit {
  public isShowTelegramWidget: boolean = true;
  public error: string = "";

  @ViewChild('telegramauth') telegramAuth!: ElementRef<HTMLDivElement>;

  /**
   * Конструктор.
   * @param sessionApi - Сервис работы с сессией аутентификации.
   * @param auth       - Сервис аутентификации.
   * @param renderer2  - Расширение базового класса для реализации пользовательского рендеринга.
   * @param document   - Интерфейс документа служит точкой входа в дерево DOM.
   * @param router     - Сервис роутинга.
   * @param store      - Хранилище NGRX.
   */
  constructor(
    private sessionApi: SessionRestApiService,
    private auth: AuthService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private store: Store<{ tuser: ISessionCreateResponse }>,
  ) {
  }

  /** Событие завершения отображения элементов HTML страницы. */
  ngAfterViewInit(): void {
    this.telegramAuth.nativeElement.appendChild(this.createScript());
  }

  /**
   * Функция получения именованного JS события из внешней функции написанной на javascript.
   * Событие помимо стандартных данных содержит объект описания пользователя, полученный через обратный вызов,
   * от сервера telegram.
   * Данная функция извлекает данные пользователя из объекта события, формирует новую структуру данных для
   * отправки на сервер и отправляет на сервер.
   * @param event - Объект события javascript.
   */
  @HostListener('window:telegram.auth.success', ['$event'])
  async onPaymentSuccess(event: Event): Promise<void> {
    const user: TelegramUser | undefined = (event as any).detail as TelegramUser | undefined;
    let req: ISessionCreateRequest;
    let rsp: ISessionCreateResponse | IResponseWithRetryAfter | undefined;

    if (user === undefined) {
      return;
    }
    this.isShowTelegramWidget = false;
    event.stopPropagation();
    // Передача данных на сервер.
    req = {
      telegramId: user.id,
      telegramAuthDateUnixtime: user.auth_date,
      telegramFirstName: user.first_name,
      telegramLastName: user.last_name,
      telegramUsername: user.username,
      telegramPhotoUri: user.photo_url,
      telegramAuthHash: user.hash,
    }
    try {
      rsp = await lastValueFrom(this.sessionApi.createSession(req))
      if (rsp === undefined) {
        this.auth.createSession();
        return
      }
    } catch (e: unknown) {
      this.error = "Ответ сервера телеграм не прошёл проверку, данные повреждены, либо подделаны.";
      return
    }
    if (Object.prototype.hasOwnProperty.call(rsp, "isBlock")) {
      const scr: ISessionCreateResponse = rsp as ISessionCreateResponse;
      this.store.dispatch(Update({data: scr}));
      this.router.navigateByUrl('/auth/wait').then((_: boolean): void => {
      });
      return
    }
  }

  /* Создание скрипта и вставка в div объект компонента. */
  private createScript(): HTMLScriptElement {
    const ret: HTMLScriptElement = this.renderer2.createElement('script');

    ret.type = 'text/javascript';
    ret.async = true;
    ret.src = 'https://telegram.org/js/telegram-widget.js?21';
    this.renderer2.setAttribute(ret, 'data-telegram-login', APP_CONFIG.telegram_bot_name);
    this.renderer2.setAttribute(ret, 'data-size', 'large');
    this.renderer2.setAttribute(ret, 'data-radius', '4');
    this.renderer2.setAttribute(ret, 'data-userpic', 'false');
    this.renderer2.setAttribute(ret, 'data-request-access', 'write');
    this.renderer2.setAttribute(ret, 'data-onauth', 'onTelegramAuth(user)');

    return ret;
  }
}
