<div
  [ngClass]="buttonStyleNormal"
  class="w-full border rounded-[4px] text-[14px]">

  <!-- Кнопка, занимающая всё пространство. -->
  <button
    [attr.type]="type$ ? type$ : null"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (click)="onClick($event)"
    [disabled]="isDisabled"
    [ngClass]="buttonStyleHover"
    [class.cursor-not-allowed]="isDisabled"
    [class.text-bbBlack]="!isDisabled && !isHover"
    class="w-full min-h-[30px] px-[12px] flex flex-row justify-between rounded-[4px] focus:outline-none">

    <!-- Текст на кнопки с позиционирование по горизонтали. -->
    <div
      [ngClass]="{'text-center': !labelLeft, 'text-left': labelLeft}"
      class="w-full my-auto leading-[16px] whitespace-nowrap"
    >{{label}}</div>
    <!-- /Текст на кнопки с позиционирование по горизонтали. -->

    <!-- Иконка, ngIF сделан для того чтобы перечитывалось название класса,
         иначе нужно отправлять событие изменения компонента, а это сложнее. -->
    <component-sprite-icon
      *ngIf="isIcon && !isDisabled && isHover"
      [name]="iconName" [width]="16" [height]="16"
      [ngClass]="{'pl-[12px]': label !== ''}"
      class="py-[7px]"></component-sprite-icon>
    <component-sprite-icon
      *ngIf="isIcon && !isDisabled && !isHover"
      [name]="iconName" [width]="16" [height]="16"
      [ngClass]="{'pl-[12px]': label !== ''}"
      class="py-[7px]"></component-sprite-icon>
    <component-sprite-icon
      *ngIf="isIcon && isDisabled"
      [name]="iconName" [width]="16" [height]="16"
      [ngClass]="{'pl-[12px]': label !== ''}"
      class="py-[7px]"></component-sprite-icon>
    <!-- /Иконка -->

  </button>
  <!-- /Кнопка, занимающая всё пространство. -->

</div>
