<div
  class="w-full flex px-[50px] py-[17px] bg-bbGrayLight">
  <div>&copy; {{copyrightYear}} <span class="capitalize">{{ companyName }}</span>.</div>
  <div class="ml-auto">
    <!-- Модальный слой отображения версий компонентов приложения. -->
    <div
      *ngIf="isShowVersion"
      class="relative">
      <div
        class="absolute w-[400px] h-[280px] -top-[280px] -left-[370px] flex overflow-x-hidden overflow-y-auto
        justify-start items-start z-2 rounded-[4px]
        border border-bbGrayTableBorder">
        <div class="relative w-full h-full max-w-[400px]">
          <div class="relative bg-white rounded-[4px] shadow h-full">
            <!-- Заголовок. -->
            <div class="flex items-center justify-between p-[8px] border-b rounded-[4px] bg-bbGrayLight">
              <h3 class="text-xl font-medium">Версии компонентов приложения</h3>
              <!-- Иконка с изменением состояния на обычная и при наведении. -->
              <component-sprite-with-hover
                (click)="toggleShowVersion()"
                [iconPassive]="'close-52px-passive'"
                [iconActive]="'close-52px-active'" [width]="19" [height]="19"
                class="mt-[3px] pl-[8px] cursor-pointer"></component-sprite-with-hover>
              <!-- /Иконка с изменением состояния на обычная и при наведении. -->
            </div>
            <!-- /Заголовок. -->

            <!-- Тело модального слоя. -->
            <div class="flex flex-col gap-[8px] p-[8px] text-base">
              <p class="leading-[16px] flex flex-col">
                <span>Версия бек-энд:</span>
                <span class="pl-[8px]">версия: {{backendVersionShort}}</span>
                <span class="pl-[8px]">сборка: {{backendVersionBuild}}</span>
                <span class="pl-[8px]">полная версия: {{backendVersionFull}}</span>
              </p>
              <p class="leading-[16px] flex flex-col">
                <span>Версия фронт-энд:</span>
                <span class="pl-[8px]">версия: {{frontendVersionShort}}</span>
                <span class="pl-[8px]">сборка: {{frontendVersionBuild}}</span>
                <span class="pl-[8px]">полная версия: {{frontendVersionFull}}</span>
              </p>
              <p class="leading-[16px] flex flex-col">
                <span>Версия схемы базы данных:</span>
                <span class="pl-[8px]">версия: {{dbVersion}}</span>
              </p>
              <p class="leading-[16px] flex flex-col">
                <span>Последняя миграция схемы базы данных:</span>
                <span class="pl-[8px]">версия: {{dbMigration | localizedDate}}</span>
              </p>
            </div>
            <!-- /Тело модального слоя. -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Модальный слой отображения версий компонентов приложения. -->
    <div
      (click)="toggleShowVersion()"
      class="text-[10px] text-bbGrayDark underline cursor-pointer select-none">
      {{backendVersionShort}}/{{frontendVersionShort}}
    </div>
  </div>
</div>
