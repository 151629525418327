import { Pipe, PipeTransform } from '@angular/core';

import { NgxMaskPipe } from "ngx-mask";


@Pipe({
  name: 'rubles'
})
export class RublesPipe implements PipeTransform {
  constructor(
    private mask: NgxMaskPipe,
  ) {
  }

  transform(value: number): string {
    const rubles: number = value / 100;
    let ret: string;

    if (value === 0) ret = 'Бесплатно';
    else {
      ret = rubles.toFixed(2);
      ret = this.mask.transform(ret, 'separator.2', {thousandSeparator: ' ', suffix: '₽'});
      ret = ret.replace(/\./, ",");
    }

    return ret;
  }
}
